$font-color: #000000;
$background-color: #002029;
$tile-color: #dad7cd;
$nav-background: #080C0A;
$nav-text: #FFF;
$right-background: #dad7cd;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: $background-color;
}

.skinny {
    font-weight: 300;
}

.nodisp {
    display: none;
}

.floatRight {
    float: right;
}

a:link,
a:visited,
a:active {
    color: white;
    text-decoration: none;
}

a:hover {
    color: #CFCF00;
}

.defaultNav {
    background-color: $nav-background;
}

.noTitle {
    background-color: transparent;

    .navTitle {
        display: none;
    }
}

.container {
    background-color: $background-color;
    height: 100%;
    width: 100%;
}

.split {
    display: flex;
    flex-wrap: wrap;

    .left {
        flex-basis: calc(100% - 400px);
        flex-grow: 1;
    }

    .right {
        flex-grow: 1;
        min-width: 330px;
        flex-basis: 330px;
        margin: 20px;
    }
}

.tileHolder {
    display: flex;
    flex-wrap: wrap;
}

.spacer {
    margin-top: 75px;
}

.section {
    background-color: $right-background;
    margin: 0px 0px 20px 0px;
    border-radius: 15px;
}

tbody>tr>th {
    cursor: pointer;
}

.loadingMessage {
    position: absolute;
    text-align: center;
    margin-top: 75px;
    color: #DDDDDD;
    font-size: 8px;
}

.viewToggleButtons {
    button {
        color: white;
        border-color: white;
        background-color: $background-color;
    }

    .Mui-selected {
        color: $background-color !important;
        border-color: white !important;
        background-color: white !important;
    }
}

.ahTab {
    text-transform: none !important;
    text-align: left !important;
    justify-content: left !important;
}