.matchTitleCardContent {
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.matchTitleCardLabel {
	display: block;
}

.matchTitleCardOn {
	color: #FFFFFF;
	font-size: 0.95rem;
}