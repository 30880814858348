.winnerSVG {
	display: flex;
	align-items: center;
	position: absolute;
	width: 24px;
	height: 24px;
	background-position: top;
	background: linear-gradient(to left, #ffffba 0%, #ffffba 20%, #d3b06a 50%, #ffffba 80%, #ffffba 100%) left/200% 100%;
	animation: background-color-change 2s infinite ease-in-out alternate;
	-webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.47,1.6c-1.24-0.77-2.56-0.8-3.71-0.07c-0.34,0.22-0.64,0.5-0.91,0.82H12h-0.02H6.14 c-0.26-0.32-0.57-0.6-0.91-0.82C4.09,0.8,2.77,0.82,1.53,1.6C0.4,2.3-0.17,3.67,0.05,5.17c0.33,2.26,2.23,4.21,5.2,5.41 c0.33,1.35,0.86,2.6,1.73,3.53c0.4,0.42,0.77,0.79,1.12,1.11c0,0,0,0,0,0s0,0,0,0c0.19,0.17,0.37,0.33,0.54,0.47 c0.45,0.47,1.06,1.32,1.29,2.63c0.13,0.92-0.64,1.29-1.67,1.29H7.16c-0.27,0-0.56,0.22-0.63,0.48l-0.66,2.43 C5.8,22.78,5.97,23,6.24,23h4.34h2.03h5.33c0.27,0,0.44-0.22,0.37-0.48l-0.66-2.43c-0.07-0.26-0.35-0.48-0.63-0.48h-1.31 c-1.02,0-1.8-0.36-1.67-1.29c0.24-1.31,0.84-2.15,1.29-2.63c0.17-0.14,0.36-0.3,0.54-0.47c0,0,0,0,0,0s0,0,0,0 c0.35-0.32,0.72-0.68,1.12-1.11c0.87-0.93,1.41-2.18,1.73-3.52c2.98-1.2,4.89-3.15,5.22-5.42C24.17,3.67,23.6,2.3,22.47,1.6z M4.82,5.42C4.78,6.32,4.79,7.35,4.89,8.4C2.92,7.35,2.02,6,1.86,4.91C1.75,4.14,1.99,3.47,2.49,3.16c0.67-0.42,1.28-0.43,1.85-0.02 c0.25,0.18,0.44,0.41,0.58,0.63c-0.04,0.38-0.07,0.82-0.09,1.3C4.8,5.18,4.8,5.3,4.82,5.42z M15.78,7.99l-1.82,1.32 c-0.21,0.15-0.31,0.48-0.23,0.72l0.69,2.14c0.08,0.25-0.03,0.32-0.23,0.17l-1.81-1.32c-0.21-0.15-0.55-0.15-0.76,0L9.8,12.35 c-0.21,0.15-0.31,0.08-0.23-0.17l0.69-2.14c0.08-0.25-0.03-0.57-0.23-0.72L8.21,7.99C8,7.84,8.04,7.72,8.3,7.72h2.24 c0.26,0,0.53-0.2,0.61-0.45l0.69-2.14c0.08-0.25,0.21-0.25,0.29,0l0.69,2.14c0.08,0.25,0.36,0.45,0.61,0.45h2.24 C15.95,7.72,15.99,7.84,15.78,7.99z M22.14,4.91c-0.16,1.1-1.06,2.45-3.05,3.5c0.1-1.03,0.11-2.04,0.08-2.94 C19.21,5.31,19.2,5.15,19.15,5c-0.02-0.45-0.05-0.85-0.09-1.21c0.14-0.23,0.34-0.47,0.6-0.65c0.57-0.41,1.17-0.4,1.85,0.02 C22.01,3.47,22.25,4.14,22.14,4.91z" /></svg>');
	mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.47,1.6c-1.24-0.77-2.56-0.8-3.71-0.07c-0.34,0.22-0.64,0.5-0.91,0.82H12h-0.02H6.14 c-0.26-0.32-0.57-0.6-0.91-0.82C4.09,0.8,2.77,0.82,1.53,1.6C0.4,2.3-0.17,3.67,0.05,5.17c0.33,2.26,2.23,4.21,5.2,5.41 c0.33,1.35,0.86,2.6,1.73,3.53c0.4,0.42,0.77,0.79,1.12,1.11c0,0,0,0,0,0s0,0,0,0c0.19,0.17,0.37,0.33,0.54,0.47 c0.45,0.47,1.06,1.32,1.29,2.63c0.13,0.92-0.64,1.29-1.67,1.29H7.16c-0.27,0-0.56,0.22-0.63,0.48l-0.66,2.43 C5.8,22.78,5.97,23,6.24,23h4.34h2.03h5.33c0.27,0,0.44-0.22,0.37-0.48l-0.66-2.43c-0.07-0.26-0.35-0.48-0.63-0.48h-1.31 c-1.02,0-1.8-0.36-1.67-1.29c0.24-1.31,0.84-2.15,1.29-2.63c0.17-0.14,0.36-0.3,0.54-0.47c0,0,0,0,0,0s0,0,0,0 c0.35-0.32,0.72-0.68,1.12-1.11c0.87-0.93,1.41-2.18,1.73-3.52c2.98-1.2,4.89-3.15,5.22-5.42C24.17,3.67,23.6,2.3,22.47,1.6z M4.82,5.42C4.78,6.32,4.79,7.35,4.89,8.4C2.92,7.35,2.02,6,1.86,4.91C1.75,4.14,1.99,3.47,2.49,3.16c0.67-0.42,1.28-0.43,1.85-0.02 c0.25,0.18,0.44,0.41,0.58,0.63c-0.04,0.38-0.07,0.82-0.09,1.3C4.8,5.18,4.8,5.3,4.82,5.42z M15.78,7.99l-1.82,1.32 c-0.21,0.15-0.31,0.48-0.23,0.72l0.69,2.14c0.08,0.25-0.03,0.32-0.23,0.17l-1.81-1.32c-0.21-0.15-0.55-0.15-0.76,0L9.8,12.35 c-0.21,0.15-0.31,0.08-0.23-0.17l0.69-2.14c0.08-0.25-0.03-0.57-0.23-0.72L8.21,7.99C8,7.84,8.04,7.72,8.3,7.72h2.24 c0.26,0,0.53-0.2,0.61-0.45l0.69-2.14c0.08-0.25,0.21-0.25,0.29,0l0.69,2.14c0.08,0.25,0.36,0.45,0.61,0.45h2.24 C15.95,7.72,15.99,7.84,15.78,7.99z M22.14,4.91c-0.16,1.1-1.06,2.45-3.05,3.5c0.1-1.03,0.11-2.04,0.08-2.94 C19.21,5.31,19.2,5.15,19.15,5c-0.02-0.45-0.05-0.85-0.09-1.21c0.14-0.23,0.34-0.47,0.6-0.65c0.57-0.41,1.17-0.4,1.85,0.02 C22.01,3.47,22.25,4.14,22.14,4.91z" /></svg>');
}

.topdownWinner > img {
	visibility: visible !important;
}

.topdownWinner > .playerNameGT {
	color: #ffffba;
	animation: text-color-change 2s infinite ease-in-out alternate;
}

.topdownWinner > .playerNameST {
	color: #ffffba;
	animation: text-color-change 2s infinite ease-in-out alternate;
}

.winner {
	border: 3px solid #d3b06a;
	animation: border-color-change 2s infinite ease-in-out alternate;
}

@keyframes background-color-change {
	to {
	  background-position: right;
	}
}

@keyframes border-color-change {
	to { border-color: #ffffba }
}

@keyframes text-color-change {
	to { color: #d3b06a }
}