@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: 'Industry';
  src: url('../Font/Industry-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Industry';
  src: url('../Font/Industry-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Industry';
  src: url('../Font/Industry-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Industry';
  src: url('../Font/Industry-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NormativePro';
  src: url('../Font/NormativePro-Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Industry', 'Roboto', sans-serif;
  background-color: #002029; 
  height: 100%;
}

.skinny {
  font-weight: 300; }

.nodisp {
  display: none; }

.floatRight {
  float: right; 
}

.pageContainer {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

@media (orientation: landscape) {
  .underToolbarContainer {
    height: calc(100% - 80px);
  }
}

@media (min-width: 0px) {
  .underToolbarContainer {
    height: calc(100% - 80px);
  }
}

.underToolbarContainer {
  padding: 16px;
  overflow: auto;
}

@media (min-width: 600px) {
  .underToolbarContainer {
    height: calc(100% - 96px);
  }
}

@media (orientation: landscape) {
  .underToolbarContainerNoPadding {
    height: calc(100% - 48px);
  }
}

@media (min-width: 0px) {
  .underToolbarContainerNoPadding {
    height: calc(100% - 48px);
  }
}

.underToolbarContainerNoPadding {
  height: calc(100% - 56px);
}

@media (min-width: 600px) {
  .underToolbarContainerNoPadding {
    height: calc(100% - 64px);
  }
}

a:link,
a:visited,
a:active {
  color: white;
  text-decoration: none; }

a:hover {
  color: #CFCF00; }

.defaultNav {
  background-color: #080C0A; }

.noTitle {
  background-color: transparent; }
  .noTitle .navTitle {
    display: none; }

.container {
  background-color: #002029;
  height: 100%;
  width: 100%; }

.split {
  display: flex;
  flex-wrap: wrap; }
  .split .left {
    flex-basis: calc(100% - 400px);
    flex-grow: 1; }
  .split .right {
    flex-grow: 1;
    min-width: 330px;
    flex-basis: 330px;
    margin: 20px; }

.tileHolder {
  display: flex;
  flex-wrap: wrap; }

.spacer {
  margin-top: 75px; }

.section {
  background-color: #dad7cd;
  margin: 0px 0px 20px 0px;
  border-radius: 15px; }

tbody > tr > th {
  cursor: pointer; }

.loadingMessage {
  position: absolute;
  text-align: center;
  margin-top: 75px;
  color: #DDDDDD;
  font-size: 8px; }

.viewToggleButtons button {
  color: white;
  border-color: white;
  background-color: #002029; }
.viewToggleButtons .Mui-selected {
  color: #002029 !important;
  border-color: white !important;
  background-color: white !important; }

.ahTab {
  text-transform: none !important;
  text-align: left !important;
  justify-content: left !important; }

.cardTitle {
  font-weight: 600 !important;
}


